<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Client Configuration</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showloaderpage">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="accesscode">
                                    Access Code
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="accesscode" v-model.trim="accesscode" required="true" maxlength="15"
                                    autofocus :class="{ 'p-invalid': submitted && !accesscode }" />
                                <small class="p-invalid p-error" v-if="v$.accesscode.$error">{{
                                    v$.accesscode.$errors[0].$message
                                    }}</small>
                                <small class="p-invalid p-error" v-if="accesscodeErr">{{ accesscodeErr }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="storagebucketid">
                                    Storage Bucket Id
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="storagebucketid" v-model.trim="storagebucketid" required="true" autofocus
                                    :class="{ 'p-invalid': submitted && !storagebucketid }" />
                                <small class="p-invalid p-error" v-if="v$.storagebucketid.$error">{{
                                    v$.storagebucketid.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="systemurl">
                                    System URL
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="systemurl" v-model.trim="systemurl" required="true" autofocus
                                    :class="{ 'p-invalid': submitted && !systemurl }" />
                                <small class="p-invalid p-error" v-if="v$.systemurl.$error">{{
                                    v$.systemurl.$errors[0].$message }}</small>
                                <small class="p-invalid p-error" v-if="systemUrlErr">{{ systemUrlErr }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="databasename">
                                    Client Database Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="databasename" v-model.trim="databasename" required="true" autofocus
                                    :class="{ 'p-invalid': submitted && !databasename }" />
                                <small class="p-invalid p-error" v-if="v$.databasename.$error">{{
                                    v$.databasename.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="emailfromname">
                                    Email From Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="emailfromname" v-model.trim="emailfromname" required="true" autofocus
                                    :class="{ 'p-invalid': submitted && !emailfromname }" />
                                <small class="p-invalid p-error" v-if="v$.emailfromname.$error">{{
                                    v$.emailfromname.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="emailfromid">
                                    Transactional Email From Id
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="emailfromid" v-model.trim="emailfromid" required="true" autofocus
                                    :class="{ 'p-invalid': submitted && !emailfromid }" />
                                <small class="p-invalid p-error" v-if="v$.emailfromid.$error">{{
                                    v$.emailfromid.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label>Billing Type <span class="p-invalid p-error"> *</span></label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="prepaid_type" name="option" v-bind:value="1" v-model="billingtype"
                                        :checked="billingtype == 1" />
                                    <label for="prepaid_type" class="p-mr-2">Prepaid</label>
                                    <RadioButton id="postpaid_type" name="option" v-bind:value="2" v-model="billingtype"
                                        :checked="billingtype == 2" />
                                    <label for="postpaid_type" class="p-mr-2">Postpaid</label>
                                </div>
                                <small class="p-invalid p-error" v-if="v$.billingtype.$error">{{
                                    v$.billingtype.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label>License Type <span class="p-invalid p-error"> *</span></label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="type_exclusive" name="option" v-bind:value="1"
                                        v-model="licensetype" :checked="licensetype == 1" />
                                    <label for="type_exclusive" class="p-mr-2">Non-exclusive</label>
                                    <RadioButton id="type_non_exclusive" name="option" v-bind:value="2"
                                        v-model="licensetype" :checked="licensetype == 2" />
                                    <label for="type_non_exclusive" class="p-mr-2">Exclusive</label>
                                </div>
                                <small class="p-invalid p-error" v-if="v$.licensetype.$error">{{
                                    v$.licensetype.$errors[0].$message
                                    }}</small>
                            </div>
                            <div>
                                <small class="p-invalid p-error" v-if="errormsg">{{ errormsg }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label>Whatsapp Approval User Login <span class="p-invalid p-error"> *</span></label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="active_permission" name="option" v-bind:value="1"
                                        v-model="login_permission" :checked="login_permission == 1" />
                                    <label for="active_permission" class="p-mr-2">Active</label>
                                    <RadioButton id="deactive_permission" name="option" v-bind:value="0"
                                        v-model="login_permission" :checked="login_permission == 0" />
                                    <label for="deactive_permission" class="p-mr-2">Deactive</label>
                                </div>
                                <small class="p-invalid p-error" v-if="v$.login_permission.$error">{{
                                    v$.login_permission.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="minimum_balance_requirement_days">
                                    Minimum Balance Requirement (In Days)
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <div class="p-inputgroup">
                                    <InputText id="minimum_balance_requirement_days"
                                        v-model="minimum_balance_requirement_days" autocomplete="off" maxlength="4"
                                        @keypress="onlyNumber" autofocus
                                        :class="{ 'p-invalid': submitted && !minimum_balance_requirement_days }" />
                                </div>
                                <small class="p-invalid p-error" v-if="v$.minimum_balance_requirement_days.$error">{{
                                    v$.minimum_balance_requirement_days.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="webBot">
                                    WebBot
                                    <!-- <span class="p-invalid p-error">*</span> -->
                                </label>
                                <Dropdown id="webBot" v-model="webBot" :options="webBotList" optionLabel="label"
                                    placeholder="Select ..." appendTo="body">
                                </Dropdown>
                                <!-- <small class="p-invalid p-error" v-if="v$.webBot.$error">{{ v$.webBot.$errors[0].$message
                                    }}</small> -->
                            </div>
                        </div>
                    </div>
                    <div class="p-d-flex p-pt-4">
                        <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                            @click="saveClientConfig" style="width: 90px"></Button>
                        <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                            class="p-button-text p-ml-auto" style="width: 90px"></Button>
                    </div>
                </div>
                <div v-if="showloaderpage" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers, email } from '@vuelidate/validators';

export default {
    data() {
        return {
            v$: useValidate(),
            accesscode: '',
            storagebucketid: '',
            billingtype: '',
            licensetype: '',
            login_permission: 0,
            systemurl: '',
            databasename: '',
            emailfromname: '',
            emailfromid: '',
            showloaderpage: false,
            clientConfig: [],
            showLoader: false,
            submitted: false,
            errormsg: '',
            systemUrlErr: '',
            accesscodeErr: '',
            routeParam: '',
            accessErrStatus: false,
            localClientName: '',
            minimum_balance_requirement_days: '',
            webBotList: [],
            webBot: '',
        };
    },
    validations() {
        return {
            accesscode: { required: helpers.withMessage('Please enter access code', required) },
            billingtype: { required: helpers.withMessage('Please select billing type', required) },
            licensetype: { required: helpers.withMessage('Please select license type', required) },
            login_permission: { required: helpers.withMessage('Please select permission type', required) },
            systemurl: { required: helpers.withMessage('Please enter system URL', required) },
            storagebucketid: { required: helpers.withMessage('Please enter storage bucket Id', required) },
            databasename: { required: helpers.withMessage('Please enter client database name', required) },
            emailfromname: { required: helpers.withMessage('Please enter email from name', required) },
            emailfromid: { required: helpers.withMessage('Please enter email from id', required), email: helpers.withMessage('Email address is invalid', email) },
            minimum_balance_requirement_days: { required: helpers.withMessage('Please enter minimum balance requirement', required) },
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.localClientName = localStorage.localClientName;
        this.getWebBotList({ clientId: this.routeParam });
        this.getclientdetails({ maa1: this.routeParam });
    },
    watch: {
        systemurl(systemurl) {
            this.errmsg = "";
            this.validateUrl(systemurl);
            if (systemurl.length === 0) {
                this.systemUrlErr = "";
                this.errStatus = false;
            }
        },
        accesscode(accesscode) {
            this.errmsg = "";
            this.validateAccessCode(accesscode);
            if (accesscode.length === 0) {
                this.accesscodeErr = "";
                this.accessErrStatus = false;
            }
        },
    },
    methods: {
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        getclientdetails(item) {
            this.showloaderpage = true;
            this.ApiService.getclientdetails(item).then((data) => {
                if (data.status == 200) {
                    this.clientConfig = data.data.records;
                    this.clientid = this.clientConfig.maa1;
                    this.accesscode = this.clientConfig.maa32;
                    this.billingtype = this.clientConfig.maa33;
                    this.licensetype = this.clientConfig.maa36;
                    this.login_permission = this.clientConfig.maa68;
                    this.systemurl = this.clientConfig.maa25;
                    this.storagebucketid = this.clientConfig.maa24;
                    this.databasename = this.clientConfig.maa29;
                    this.emailfromid = this.clientConfig.maa22;
                    this.emailfromname = this.clientConfig.maa23;
                    this.minimum_balance_requirement_days = this.clientConfig.maa38;
                    if (this.clientConfig.maa42) {
                        let webbotId = this.webBotList.filter((item) => {
                            return item.value == this.clientConfig.maa42;
                        });
                        if (webbotId.length > 0) {
                            this.webBot = webbotId[0];
                        }
                    }
                }
                this.showloaderpage = false;
            });
        },

        async getWebBotList(item) {
            this.showloaderpage = true;
            await this.ApiService.getWebBotList(item).then((data) => {
                if (data.status == 200) {
                    this.webBotList = data.data;
                } else {
                    this.webBotList = null;
                }
            });
        },

        saveClientConfig() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['maa1'] = this.clientid
            fields['maa32'] = this.accesscode;
            fields['maa33'] = this.billingtype;
            fields['maa36'] = this.licensetype;
            fields['maa68'] = this.login_permission;
            fields['maa25'] = this.systemurl;
            fields['maa24'] = this.storagebucketid;
            fields['maa29'] = this.databasename;
            fields['maa22'] = this.emailfromid;
            fields['maa23'] = this.emailfromname;
            fields['maa38'] = this.minimum_balance_requirement_days;
            fields['maa42'] = this.webBot.value;

            this.v$.$validate();
            if (!this.v$.$error && !this.systemUrlErr) {
                this.showLoader = true;
                this.ApiService.updateclientconfig(fields).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addClientFundStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.goToDashboard();
                        }, 4000);
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.routeParam },

            });
            // window.open(routeData.href, "_self");
        },
        goToPrevious() {
            router.push({
                name: "clientinfo",
                params: { clientSubId: this.routeParam },

            });
            // window.open(routeData.href, "_self");
        },
        goToNext() {
            router.push({
                name: "clientplan",
                params: { clientSubId: this.routeParam },

            });
            // // window.open(routeData.href, "_self");
        },

        validateUrl(url) {
            this.errStatus = false;
            if (!this.validUrl(url)) {
                this.systemUrlErr = "Please enter a valid URL";
                this.errStatus = true;
            }
            if (this.errStatus === false) {
                this.systemUrlErr = "";
                return true;
            }
        },


        validUrl: function (url) {

            // var re = /^[^ "]+(\.[a-zA-Z]{2,})+$/;
            var re = /^(?!((ftp|http(s?)):\/\/))?[^ "]+(\.[a-zA-Z]{2,})+$/;
            return re.test(url);
        },

        validateAccessCode(accesscode) {
            this.accessErrStatus = false;
            if (!this.validAccessCode(accesscode)) {
                this.accesscodeErr = "Please enter a valid access code";
                this.accessErrStatus = true;
            }
            if (this.accessErrStatus === false) {
                this.accesscodeErr = "";
                return true;
            }
        },
        validAccessCode: function (accesscode) {
            // var re = /^[^ "]+(\.[a-zA-Z]{2,})+$/;
            var re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
            return re.test(accesscode);
        },
    },
};
</script>

<style scoped></style>
